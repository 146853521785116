import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import { Card, CardContent, Alert, AlertDescription } from './components/ui';
import config from './config';

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-gray-50/80 backdrop-blur-sm flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
      <Loader2 className="h-12 w-12 animate-spin text-blue-500 mx-auto mb-4" />
      <p className="text-gray-600 font-medium">Analyzing ticket...</p>
      <p className="text-gray-400 text-sm mt-2">This may take a few moments</p>
    </div>
  </div>
);

const AnalysisContent = ({ content }) => {
  if (!content) return <p className="text-gray-500">No analysis available for this ticket.</p>;

  // Check if content is HTML string
  if (typeof content === 'string') {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  // Fallback to structured data display
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Analysis Results</h3>
      <div className="prose prose-blue max-w-none">
        {content.nextSteps && (
          <>
            <h4 className="text-base font-semibold mt-4">Recommended Next Steps</h4>
            <ul className="space-y-2">
              {content.nextSteps.map((step, index) => (
                <li key={index} className="flex items-start gap-2">
                  <span className="font-medium min-w-8">{index + 1}.</span>
                  <span>{step}</span>
                </li>
              ))}
            </ul>
          </>
        )}
        
        {content.additionalInfo && (
          <div className="mt-6">
            <h4 className="text-base font-semibold mb-2">Additional Information</h4>
            <p className="text-gray-700">{content.additionalInfo}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const App = () => {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnalysis = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const ticketData = {
          "Ticket ID": params.get('ticketId'),
          "Summary": params.get('summary'),
          "Details": params.get('description'),
          "Ticket Type": params.get('type')
        };

        if (!ticketData["Ticket ID"]) {
          throw new Error('No ticket information provided');
        }

        const response = await fetch(`${config.API_URL}/analyze`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Origin': window.location.origin
          },
          body: JSON.stringify(ticketData),
        });

        if (!response.ok) {
          throw new Error(`Analysis failed: ${response.statusText}`);
        }

        // Try to get response as text first
        const responseText = await response.text();
        let data;
        
        try {
          // Try to parse as JSON
          data = JSON.parse(responseText);
        } catch {
          // If not JSON, treat as HTML
          data = responseText;
        }

        setAnalysis(data);
      } catch (err) {
        setError(err.message);
        console.error('Analysis error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalysis();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <Alert variant="destructive" className="m-4">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="p-4">
      <Card>
        <CardContent>
          <AnalysisContent content={analysis} />
        </CardContent>
      </Card>
    </div>
  );
};

export default App;